import request from "@/utils/request";
import request1 from '@/utils/request1';

// HomeOverview
export function homeOverview(params) {
  return request({ url: `/home_overview/`, method: "get", params });
}

// 库存预警
export function stockWarn(data) {
  return request1({ url: '/home/QHomeApi', method: 'post', data })
}

// 消息通知
export function onMessage(data) {
  return request1({ url: '/excel/MessageNotifyApi', method: 'post', data })
}
